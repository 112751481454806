@use '../abstract' as *

.team_member
	//height: 100%
	border-radius: 20px
	overflow: hidden
	
	.image
		position: relative
		
		width: 100%
		aspect-ratio: .7
		background-color: $color-blanc
		
		border-radius: 20px 20px 0 0
		
		overflow: hidden
		
		img
			object-fit: cover
			transition: filter .3s ease
	
	> .content
		height: 100%
		
		padding: 1.5rem
		background-color: white
		
		display: flex
		flex-direction: column
		gap: 4px
		
		color: $color-bleu-principal
		
		font-family: "Inter Tight Light", sans-serif
		font-size: 15px
		line-height: 22px
		
		.name
			margin-bottom: .75rem
			
			text-transform: uppercase
			
			@include z-typo-paragraph-inter-tight-medium-18-21
		
		.mail
			@include correctTransformGlitch
	
	&:hover
		.image
			img
				filter: grayscale(1)
	
	@include zi-media
		> .content
			.name
				@include z-typo-paragraph-inter-tight-medium-15-19
