@use '../abstract' as *

.why_chose_us_item
	border-radius: 20px
	
	background-color: white
	transition: background-color .3s ease
	
	//padding: 40px 70px 40px 50px
	
	display: flex
	flex-direction: column
	
	color: $color-bleu-principal
	
	.top
		padding: 40px 70px 40px 50px
	
	.top
		cursor: pointer
		
		display: flex
		flex-direction: row
		gap: 2rem
		justify-content: space-between
		align-items: center
		
		.question
			@include z-typo-paragraph-inter-tight-medium-25-30
		
		.icon_container
			position: relative
			
			display: flex
			height: fit-content
			
			.icon
				transition: transform .3s ease
			
			&:before
				content: ''
				position: absolute
				
				top: 50%
				left: 50%
				
				height: 175%
				width: auto
				aspect-ratio: 1
				
				transform: translate(-50%, -50%)
				
				border-radius: 999px
				border: 2px solid $color-bleu-principal
	
	.bottom
		height: 0
		overflow: hidden
		
		.answer
			visibility: hidden
			padding: 0 70px 40px 50px
			@include z-typo-paragraph-inter-tight-light-18-21
	
	&:hover
		background-color: rgba(white, .5)
	
	&.opened
		.top
			.icon_container
				.icon
					transform: rotate(180deg)
	
	@include zi-media
		.top
			padding: 1.5rem
			
			.question
				@include z-typo-paragraph-inter-tight-medium-18-21
		
		.bottom
			.answer
				padding: 0 1.5rem 1.5rem
				@include z-typo-paragraph-inter-tight-light-18-21
