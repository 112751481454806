@use '../abstract' as *

.link
	color: $color-orange-principal
	
	width: fit-content
	
	display: flex
	flex-direction: row
	gap: 1rem
	
	align-items: center
	
	.text
		@include z-typo-paragraph-inter-tight-semibold-20-24
	
	.arrow
		transition: transform .3s ease
	
	&:hover
		.arrow
			transform: translateX(1rem)
