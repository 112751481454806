@use 'abstract' as *

.page_content
	display: flex
	flex-direction: column
	gap: 2.5rem
	overflow: hidden
	
	span, p
		@include correctTransformGlitch
	
	.banner
		position: relative
		background: #2D3243
		height: 100vh

		display: flex
		flex-direction: row
		z-index: -2

		.background_svg
			position: absolute
			height: 100%
			width: auto
			z-index: -1

		.left
			height: 100%
			display: flex
			flex-direction: column
			justify-content: flex-end
			gap: 3rem

			padding-left: 10rem
			padding-bottom: 6rem

			.text
				display: flex
				flex-direction: column
				gap: 1.5rem

				max-width: 50rem

				@media(max-width: 90rem)
					max-width: 25rem

				.title
					@include z-typo-heading-inter-tight-semibold-50-60
					color: $color-orange-principal
					font-size: 45px
					font-height: 54px

				.description
					@include z-typo-paragraph-inter-tight-medium-25-30
					color: white

			.link
				@include correctTransformGlitch
				@include z-typo-paragraph-inter-tight-semibold-20-24

				width: fit-content

				background: $color-orange-principal

				padding: 1rem 1.5rem
				border-radius: 10px

				transition: background-color .3s ease


				.link_text
					color: white
					scale: 1

					transition: scale .3s ease

				&:hover
					background: #BF5E2C
					.link_text
						scale: 1.1
		.right
			position: absolute
			right: 0
			bottom: 0

			height: 100%
			width: 60%

			.image
				height: 100%
				width: 100%
				object-fit: cover
				object-position: left

	.our_role
		@include z-padding-inline
		
		display: flex
		flex-direction: column
		gap: 30px
		justify-content: center
		align-items: center
		
		padding-bottom: 100px
		
		> .title
			font-family: Inter Tight SemiBold, sans-serif
			font-size: 50px
			color: $color-orange-principal
			
			@include correctTransformGlitch
		
		.list
			display: flex
			flex-direction: row
			gap: 1rem
			
			.our_role_element
				flex: 1
				
				border-radius: 12px
				padding: 30px 36px
				
				background-color: white
				
				display: grid
				grid: 'number title' 'content content' / auto 1fr
				gap: 2rem 1rem
				align-items: center
				
				.number
					position: relative
					
					grid-area: number
					
					width: 2.5rem
					height: auto
					aspect-ratio: 1
					
					display: flex
					justify-content: center
					align-items: center
					
					.text
						position: absolute
						top: 40%
						left: 50%
						transform: translate(-50%, -50%)
						
						//region Font
						font-family: "Cormorant Garamond Bold Italic", sans-serif
						font-size: 38px
						line-height: 1em
					//endregion
					
					&:before
						content: ''
						position: absolute
						inset: 0
						
						border-radius: 999px
						border: 3px solid $color-bleu-principal
				
				.title
					grid-area: title
					
					text-transform: uppercase
					font-family: Inter Tight SemiBold, sans-serif
					font-size: 20px
					line-height: 24px
				
				.content
					grid-area: content
					font-family: "Inter Tight Light", sans-serif
					font-size: 18px
					line-height: 21px
				
				&:nth-child(n + 3)
					.number
						.text
							top: 35%
							font-size: 34px
	
	.areas_of_competences
		position: relative
		
		> .title
			position: absolute
			top: 4rem
			left: var(--padding-inline-value)
			z-index: 2
			
			color: white
			
			span
				font-family: "Inter Tight Medium", sans-serif
				font-size: 40px
				line-height: 40px
			
			em
				font-family: "Cormorant Garamond Bold Italic", sans-serif
				font-size: 50px
				line-height: 40px
		
		.list
			display: flex
			flex-direction: column
			gap: 0
			
			.areas_of_competences_element
				@include correctTransformGlitch
				padding: 10% 7%
				
				min-height: 100vh
				max-height: 100vh
				
				z-index: 1
				
				// Not exact
				border-radius: 6rem 6rem 0 0
				
				display: flex
				
				background-color: var(--area-competence-color)
				
				> .content
					background-color: white
					border-radius: 10px
					
					padding: 4rem 4rem
					
					max-height: 100%
					width: 100%
					
					//display: flex
					//flex-direction: column
					display: grid
					grid-template-rows: auto 1fr
					gap: 2rem
					
					.top
						display: flex
						flex-direction: column
						gap: 2rem
						
						.category
							display: flex
							flex-direction: row
							gap: 1rem
							color: var(--area-competence-color)
							
							.number
								position: relative
								
								border-radius: 999px
								
								// Problem on safari with height: 100%
								height: 46px
								width: auto
								aspect-ratio: 1
								
								@supports not (aspect-ratio: 16 / 9)
									height: 46px
									width: 46px
									aspect-ratio: unset
								
								display: flex
								justify-content: center
								align-items: center
								
								border: 2px solid var(--area-competence-color)
								
								.value
									position: absolute
									top: 40%
									left: 50%
									transform: translate(-50%, -50%)
									
									font-family: "Cormorant Garamond Medium Italic", sans-serif
									font-size: 40px
									line-height: 48px
							
							.name
								padding: .5rem 1rem
								
								border-radius: 999px
								border: 2px solid var(--area-competence-color)
								
								font-family: "Cormorant Garamond Bold Italic", sans-serif
								font-size: 22px
								line-height: 26px
						
						.content
							display: flex
							flex-direction: column
							gap: 1.5rem
							justify-content: center
							align-items: center
							
							> .title
								color: var(--area-competence-color)
								
								text-transform: uppercase
								font-family: "Inter Tight Bold", sans-serif
								font-size: 40px
								line-height: 49px
							
							.description
								font-family: "Inter Tight Medium", sans-serif
								font-size: 18px
								line-height: 25px
								
								max-width: 50ch
								text-align: center
								
								em
									color: var(--area-competence-color)
									
									font-family: "Cormorant Garamond Bold Italic", sans-serif
									font-size: 25px
									line-height: 1em
					
					.icon
						position: relative
						justify-self: center
						display: flex
						justify-content: center
						align-items: center
						
						img
							position: absolute
							top: 50%
							left: 50%
							
							transform: translate(-50%, -50%)
							max-height: min(20vh, 100%)
							max-width: min(20vw, 25vh)
							width: auto
							height: auto
			
			//height: 100%
			//width: 100%
			
			// Change colors
			&, > div
				&:first-child
					padding-top: max(10%, 12rem)
					--area-competence-color: #{$color-bordeaux-conseil}
				
				&:nth-child(2)
					--area-competence-color: #{$color-jaune-social}
				
				&:nth-child(3)
					--area-competence-color: #{$color-turquoise-audit}
				
				&:nth-child(n+3)
					> .content
						.top
							.category
								.number
									.value
										font-size: 33px
										line-height: 39px
	
	.income_birth_container
		min-height: 300vh
		
		overflow: hidden
		
		.income_birth_content
			@include correctTransformGlitch
			@include z-padding-inline
			
			height: 100vh
			width: 100%
			
			display: flex
			flex-direction: row
			align-items: center
			justify-content: center
		
		.end
			width: 100%
			max-width: 1000px
			margin: auto
			
			.center
				display: grid
				grid-template-rows: 1fr 1fr auto
				
				gap: 1.5rem
				
				width: 100%
				
				@include zi-media('>', tablet)
					> *
						> *
							border-radius: 12px
							background-color: white
							
							padding: .5rem 0
				
				@include zi-media
					> *:not(.links)
						border-radius: 12px
						background-color: white
						
						padding: .5rem 0
				
				.line_1
					display: flex
					flex-direction: row
					gap: 1.5rem
				
				.line_2
					display: flex
					flex-direction: row
					gap: 1.5rem
				
				.list
					display: flex
					flex-direction: row
					gap: 1.5rem
				
				.cegex_and_qubbe
					flex: 5.5
					
					display: flex
					flex-direction: column
					gap: 1rem
					
					justify-content: center
					align-items: center
					
					svg
						max-width: 180px
					
					span
						font-size: 30px
						line-height: 37px
						font-family: "Cormorant Garamond Bold", sans-serif
						text-align: center
						
						em
							font-family: "Cormorant Garamond Bold Italic", sans-serif
				
				.experience
					flex: 4.5

					background-color: $color-dark-blue
					color: $color-blanc
					
					display: flex
					justify-content: center
					align-items: center
					text-align: center
					
					@include z-typo-paragraph-inter-tight-semibold-30-36
					font-size: 35px
				
				.twenty_twenty_two
					position: relative
					
					flex: 6
					
					display: flex
					flex-direction: column
					gap: 1rem
					justify-content: center
					align-items: center
					
					background-color: $color-orange-principal
					color: white
					@include z-typo-heading-inter-tight-medium-40-49

					em
						@include z-typo-heading-cormorant-garamond-bold-italic-50-50
						font-family: "Cormorant Garamond Bold Italic", sans-serif

				.service
					flex: 4
					
					display: flex
					justify-content: center
					align-items: center
					text-align: center
					
					font-family: "Inter Tight Medium", sans-serif
					font-size: 30px
					line-height: 36px
					
					em
						font-family: "Cormorant Garamond Bold Italic", sans-serif
						font-size: 38px
						line-height: 46px
				
				.conseil
					background-color: $color-bordeaux-conseil
				
				.audit
					background-color: $color-turquoise-audit
				
				.social
					background-color: $color-jaune-social
				
				.conseil, .audit, .social
					display: flex
					justify-content: center
					align-items: center
					
					flex: 1
					
					padding: 1.5rem 6rem
					
					color: white
					
					font-family: "Inter Tight Medium", sans-serif
					font-size: 30px
					line-height: 36px
				
				.links
					display: flex
					flex-direction: row
					gap: 1rem
					
					justify-self: flex-end
					
					> *
						padding: 1.25rem
						
						background-color: transparent
						border-radius: 10px
						border: 2px solid $color-orange-principal
						color: $color-orange-principal
						
						display: flex
						justify-content: center
						align-items: center
					
					.text
						font-family: Inter Tight SemiBold, sans-serif
						font-size: 20px
						line-height: 24px
					
					.icon_container
						background-color: transparent
						height: 100%
						width: fit-content
						
						.icon
							transition: transform .3s ease
					
					&:hover
						.text
							color: white
							background-color: $color-orange-principal
						
						.icon_container
							background-color: $color-orange-principal
							
							.icon
								stroke: white
								transform: translateX(4px)
			
			.discover_us
				text-transform: uppercase
				white-space: nowrap
				
				font-family: Inter Tight SemiBold, sans-serif
				font-size: 31px
				line-height: 37px
				
				color: $color-orange-principal
	
	.competences_container
		margin-block: 4rem
		@include z-padding-inline
		
		.competences_content
			display: grid
			grid-template-columns: repeat(2, 1fr)
			align-items: center
			
			background-color: white
			
			border-radius: 20px
			overflow: hidden
			
			.image_left
				position: relative
				height: 100%
				width: 100%
				
				img
					width: 100%
					height: 100%
					object-fit: cover
			
			.right
				padding: 6rem
				
				display: flex
				flex-direction: column
				gap: 1rem
				
				.title
					font-family: "Inter Tight Medium", sans-serif
					font-size: 40px
					line-height: 50px
					color: $color-bleu-principal
					
					em
						font-family: "Cormorant Garamond Bold Italic", sans-serif
						font-size: 50px
						line-height: 50px
				
				.description
					color: $color-bleu-principal
					
					font-family: "Inter Tight Light", sans-serif
					font-size: 18px
					line-height: 21px
					
					max-width: 33ch
				
				.list
					padding-top: .5rem
					
					display: grid
					grid-template-columns: repeat(3, 1fr)
					flex-direction: row
					gap: 20px
					
					width: fit-content
					
					.item
						padding: .5rem 1.25rem
						
						display: flex
						justify-content: center
						align-items: center
						
						border-radius: 10px
						color: white
						background-color: $color-orange-principal
						
						@include z-typo-paragraph-inter-tight-medium-20-24
	
	.team
		padding-block: 6rem
		
		display: grid
		grid: 'top_left link' auto 'list list' 1fr / auto auto
		justify-content: space-between
		gap: 2.5rem 4rem
		
		.left
			margin-left: var(--padding-inline-value)
			
			grid-area: top_left
			display: flex
			flex-direction: column
			gap: .5rem
			
			.title
				font-family: "Inter Tight Bold", sans-serif
				font-size: 40px
				line-height: 49px
				
				color: $color-bleu-principal
				text-transform: uppercase
			
			.description
				font-family: "Inter Tight Medium", sans-serif
				font-size: 18px
				line-height: 21px
				
				color: $color-bleu-principal
				
				max-width: 47ch
		
		.link
			margin-right: var(--padding-inline-value)
			
			grid-area: link
			border-radius: 10px
			background-color: $color-bleu-principal
			color: white
			
			display: flex
			justify-self: flex-end
			align-self: center
			
			width: fit-content
			height: fit-content
			
			padding: 20px 40px
			
			font-family: Inter Tight SemiBold, sans-serif
			font-size: 20px
			line-height: 24px
			
			transition: background-color .3s ease
			
			&:hover
				background-color: #2D3243
		
		.list
			grid-area: list
			width: 100%
			
			@include z-padding-inline
			
			.next_arrow, .prev_arrow
				position: absolute
				top: 50%
				z-index: 1
				
				cursor: pointer
				
				&.disabled
					display: none
			
			.prev_arrow
				left: 2rem
				transform: translate(0, -50%) rotate(.5turn)
			
			.next_arrow
				right: 2rem
				transform: translate(0, -50%)
	
	.why_chose_us
		@include z-padding-inline
		padding-block: 6rem
		
		color: $color-bleu-principal
		
		display: grid
		grid-template-columns: 4fr 6fr
		gap: 4rem
		
		.left
			display: flex
			flex-direction: column
			gap: 20px
			
			.title
				@include z-typo-heading-cormorant-garamond-bold-italic-50-50
				
				@include correctTransformGlitch
			
			.description
				@include z-typo-paragraph-inter-tight-medium-18-21
				max-width: 46ch
				
				em
					@include z-typo-paragraph-cormorant-garamond-bold-italic-22-26
			
			.link
				margin-top: 12px
		
		.right
			display: flex
			flex-direction: column
			gap: 15px
	
	.our_actuality
		@include z-padding-inline
		padding-block: 6rem
		
		display: grid
		grid: 'title link' auto 'list list' / 1fr auto
		gap: 3.5rem 4rem
		place-items: center
		
		> .title
			justify-self: flex-start
			@include z-typo-heading-inter-tight-semibold-50-60
			grid-area: title
		
		.link
			grid-area: link
			display: flex
			flex-direction: row
			gap: 1.25rem
			
			justify-content: center
			align-items: center
			
			color: white
			background-color: $color-bleu-principal
			
			border-radius: 10px
			
			padding: 20px 40px
			
			@include z-typo-paragraph-inter-tight-semibold-20-24
		
		.list
			grid-area: list
			width: 100%
			
			.linkedin_post
				display: flex
				flex-direction: column
				
				gap: 20px
				
				.image_container
					position: relative
					
					width: 100%
					height: auto
					aspect-ratio: 1
					
					border-radius: 20px
					overflow: hidden
					
					img
						object-fit: cover
				
				.content
					display: flex
					flex-direction: column
					gap: .5rem
					
					@include z-typo-paragraph-inter-tight-medium-18-21
					
					.title
						text-transform: uppercase
					
					.description
	
	.newsletter
		@include z-margin-inline
		margin-block: 2rem
		
		display: grid
		grid-template-columns: 4fr 6fr
		
		background-color: white
		border-radius: 20px
		overflow: hidden
		
		.left
			position: relative
			
			img
				object-fit: cover
		
		.right
			padding: 3rem 8rem
	
	@include zi-media('>', tablet)
		.income_birth_container
			.title
				position: absolute
				top: 50%
				left: var(--padding-inline-value)
				transform: translate(0, -50%)
				
				font-family: Inter Tight SemiBold, sans-serif
				font-size: 63px
				line-height: 76px
				
				em
					font-family: "Cormorant Garamond Bold Italic", sans-serif
					font-size: 75px
					line-height: 91px
			
			.income_birth_content
				.end
					.floating_elements
						position: absolute
						inset: 0
						
						> div
							position: static
							width: 100%
							height: 100%
						
						> div > *
							position: absolute
						
						.bottom_left
							top: 70%
							left: 12%
							transform: translate(50%, 50%)
						
						.bottom
							bottom: 5%
							left: 35%
							transform: translate(50%, 0%)
						
						.top_left
							top: 0
							left: -20%
							transform: translate(50%, 50%)
						
						.top_right
							top: 0
							left: 55%
							transform: translate(50%, 0%)
						
						.right
							top: 50%
							left: 100%
							transform: translate(-50%, -50%)
					
					.line
						position: absolute
						
						top: 50%
						left: 75vw
						width: 150vw
						transform: translateY(-50%)
					
					.discover_us
						position: absolute
						
						bottom: 54%
						left: 75vw
					
					.center
						.cegex_and_qubbe
							transform: translateX(57vw)
						
						.experience
							transform: translateX(82vw)
						
						.twenty_twenty_two
							transform: translateX(75vw)
						
						.service
							transform: translateX(125vw)
						
						.conseil
							transform: translateX(82vw)
						
						.audit
							transform: translateX(105vw)
						
						.social
							transform: translateX(115vw)
						
						.links
							.text
								transform: translateX(125vw)
							
							.icon_container
								transform: translateX(140vw)
	@include zi-media
		gap: 60px
		.banner
			flex-direction: column
			gap: 2rem

			.background_svg
				display: none

			.left
				height: auto
				padding: 10rem 2rem 0
				justify-content: flex-start
				align-items: center

				.text
					text-align: center
					max-width: none

					.title
						@include z-typo-paragraph-inter-tight-semibold-25-30

					.description
						@include z-typo-paragraph-inter-tight-medium-15-19

				.link
					@include z-typo-paragraph-inter-tight-semibold-15-19

					padding: .5rem 1.5rem

			.right
				position: relative
				width: 100%

				.image_mobile
					object-fit: cover
					object-position: top

				.image
					object-fit: cover

		.our_role
			gap: 10px
			
			> .title
				@include z-typo-paragraph-inter-tight-semibold-30-36
			
			.list
				flex-direction: column
				gap: 20px
				
				.our_role_element
					gap: .75rem 1rem
					
					.number
						width: 30px
						
						.text
							@include z-typo-paragraph-cormorant-garamond-bold-italic-25-30
						
						&:before
							border-width: 2px
					
					.title
						@include z-typo-paragraph-inter-tight-semibold-15-19
					
					.content
						@include z-typo-paragraph-inter-tight-light-15-19
					
					&:nth-child(n + 3)
						.number
							.text
								top: 35%
								@include z-typo-paragraph-cormorant-garamond-bold-italic-20-24
		
		.areas_of_competences
			> .title
				top: 50px
				left: 50%
				transform: translateX(-50%)
				width: 100%
				
				text-align: center
				
				span
					@include z-typo-paragraph-inter-tight-medium-25-30
					font-size: 31px
					line-height: 31px
					
					em
						@include z-typo-paragraph-cormorant-garamond-bold-italic-26-31
						font-size: 39px
			
			.list
				.areas_of_competences_element
					text-align: center
					padding: 2rem
					max-height: 100vh
					
					border-radius: 2rem 2rem 0 0
					
					&:first-child
						padding-top: 2rem + 9rem
					
					> .content
						gap: 3rem
						
						.top
							gap: 20px
							
							.category
								justify-content: center
								align-items: center
								gap: 10px
							
							.content
								gap: 10px
								
								> .title
									@include z-typo-paragraph-inter-tight-bold-25-30
								
								.description
									&, em
										@include z-typo-paragraph-inter-tight-medium-15-19
						
						.icon
							flex: 1
							width: auto
							height: 100%
							max-height: 100%
							min-width: 100%
							
							img
								object-fit: contain
								object-position: center bottom
								max-width: 100%
								max-height: 100%
								height: 100%
					height: auto
		.income_birth_container
			min-height: 195vh
			overflow: visible
			
			//region Debug
			//position: relative
			//
			//&:before
			//	content: ''
			//	position: absolute
			//	inset: 0
			//	width: 100%
			//
			//	background-image: linear-gradient(to bottom, black 10%, transparent 0%)
			//	background-position: top
			//	background-size: 100% 10px
			//	background-repeat: repeat-y
			//endregion
			
			.income_birth_content
				display: flex
				flex-direction: column
				gap: 1.5rem
				justify-content: flex-start
				
				max-height: 100vh
				
				.floating_elements
					display: none
				
				.end
					margin: 0
					position: relative
					
					display: flex
					flex-direction: column
					gap: 1rem
					justify-content: flex-end
					align-items: center
					padding-bottom: 2rem
					
					height: 100%
					
					.discover_us
						position: absolute
						top: 0
					
					.line
						z-index: -1
						position: absolute
						left: 50%
						transform-origin: left top
						transform: rotate(90deg) translateY(-50%)
						
						width: 150vh
						
						top: 4rem
					
					.center
						grid: 'cegex_qubbe twenty_twenty_two' 2fr 'experience experience' 1.3fr 'service audit' .6fr 'service social' .6fr 'conseil conseil' .6fr 'discover discover' .6fr / 1fr 1fr
						gap: 10px
						
						.cegex_and_qubbe
							grid-area: cegex_qubbe
							padding: 1rem
							
							span
								@include z-typo-paragraph-cormorant-garamond-bold-italic-22-26
								font-family: "Cormorant Garamond Bold", sans-serif
								
								em
									font-family: "Cormorant Garamond Bold Italic", sans-serif
							
							svg
								width: 100%
								height: auto
						
						.experience
							grid-area: experience
							padding: 1rem
							
							@include z-typo-paragraph-inter-tight-semibold-20-24
							font-size: 22px
							

						.twenty_twenty_two
							grid-area: twenty_twenty_two
							padding: 1rem
							@include z-typo-paragraph-inter-tight-medium-20-24
							font-size: 22px

							em
								@include z-typo-paragraph-cormorant-garamond-bold-italic-32-39
								font-size: 29px
						
						.service
							grid-area: service
							padding: 1rem
							
							@include z-typo-paragraph-inter-tight-medium-20-24
							
							em
								@include z-typo-paragraph-cormorant-garamond-bold-italic-20-24
								font-size: 23px
						
						.conseil
							grid-area: conseil
						
						.audit
							grid-area: audit
						
						.social
							grid-area: social
						
						.conseil, .social, .audit
							padding: 1.25rem
							@include z-typo-paragraph-inter-tight-medium-18-21
						
						.links
							grid-area: discover
							
							> *
								padding: 1rem
								
								display: flex
								align-items: center
							
							.icon
								max-width: 4rem
			
			// Transform
			.cegex_and_qubbe
				transform: translateY(20vh)
			
			.experience
				transform: translateY(35vh)
			
			.twenty_twenty_two
				transform: translateY(30vh)
			
			.service
				transform: translateY(60vh)
			
			.conseil
				transform: translateY(70vh)
			
			.audit
				transform: translateY(45vh)
			
			.social
				transform: translateY(50vh)
			
			.links
				transform: translateY(87vh)
		.competences_container
			.competences_content
				grid: auto 1fr / 100%
				gap: 0
				
				.image_left
					width: 100%
					height: auto
					aspect-ratio: 1
				
				.right
					padding: 1.25rem
					
					.title
						text-align: center
						@include z-typo-paragraph-inter-tight-medium-25-30
						
						em
							@include z-typo-paragraph-cormorant-garamond-bold-italic-30-37
					
					.list
						gap: 4px
						justify-content: space-between
						
						.item
							@include z-typo-paragraph-inter-tight-medium-18-21
		.team
			grid: 'top_left' 'list' 'link'
			padding: 0
			
			.left
				margin: 0
				
				> .title
					@include z-typo-paragraph-inter-tight-bold-25-30
					font-size: 30px
					line-height: 36px
			
			> *
				@include z-padding-inline
			
			.list
				overflow: hidden
				padding: 0 1rem
				
				.next_arrow, .prev_arrow
					display: none
			
			.link
				justify-self: center
				margin: 0
		
		.why_chose_us
			display: flex
			flex-direction: column
			gap: 1rem
			padding-block: 20px
			
			.left
				align-items: center
				text-align: center
				
				.title
					@include z-typo-paragraph-cormorant-garamond-bold-italic-30-37
				
				.description
					margin-bottom: 10px
		
		.our_actuality
			padding: 0
			
			grid: 'title' 'list' 'link'
			gap: 35px
			
			> .title
				margin-inline: auto
			
			> *
				@include z-padding-inline
			
			.title
				@include z-typo-paragraph-inter-tight-semibold-25-30
			
			.link
				@include z-typo-paragraph-inter-tight-semibold-18-21
		
		.newsletter
			display: flex
			flex-direction: column
			margin-top: 65px
			margin-bottom: -20px
			
			.left
				width: 100%
				height: auto
				aspect-ratio: 4/3
			
			.right
				padding: .5rem 2rem 1.5rem
	@include zi-media('<=', mobile)
		.income_birth_container
			.income_birth_content
				.end
					.center
						.links
							.text
								@include z-typo-paragraph-inter-tight-semibold-18-21
							
							.icon
								height: 75%
		.areas_of_competences
			.list
				.areas_of_competences_element
					> .content
						gap: 2rem
						padding: 2rem 2rem
						
						.icon
							img
								max-height: min(75%, 200px)
								max-width: min(75%, 250px)
		.competences_container
			.competences_content
				.right
					.list
						align-self: center
						
						.item
							padding: .5rem .75rem
